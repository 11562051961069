export type TEngagement = {
  engagementId: number;
  scheduledTime: string;
  teacherId: number;
  learnerId: number;
  courseId: number;
  courseLevel?: string;
  engagementType: string;
  engagementDate: number;
  engagementMethod: string;
  location?: string;
  siteSafety?: string;
  participants: string;
  projectsWorkedOn?: string;
  currentWork?: string;
  agreedMilestoneMovements?: string;
  learningEnvironment?: string;
  scopeOfWork?: string;
  evaluatorComments?: string;
  learnerComments?: string;
  previousGoalsProgress?: string;
  agreedGoalsForNextPeriod?: string;
  annualProgressReview?: string;
  annualDevelopmentGoals?: string;
  annualSupportPlan?: string;
  checkInType?: string;
  checkInProgress?: string;
  checkInFees?: string;
  checkInLearnerEngagement?: string;
  checkInLLN?: string;
  checkInLearningEnvironment?: string;
  checkInUpdateAgreedGoals?: string;
  isDraft: boolean;
  learnerName: string;
  courseName: string;
  version: number;
  isAnnualReview?: boolean;
  notes?: string;
  milestones?: Record<string, any>;
  milestoneStatements?: Record<string, any>;
  learnerEngagement?: string;
  languageLiteracyNumeracy?: string;
  goalsComments?: Record<string, any>;
  professionalDevelopmentGoals?: string;
  agreedLearningSupport?: string;
  learningExperienceFeedback?: string;
  fees?: string;
  nextVisitDate?: string;
  courseCode?: string;
  upcomingScopeOfWork?: string;
  latestGoals: TEngagementDb;
  latestFaceToFace: TEngagementDb;
  latestAnnualReview: TEngagementDb;
};

export type EngagementEvent = Pick<
  TEngagement,
  | "learnerId"
  | "learnerName"
  | "courseId"
  | "courseName"
  | "engagementId"
  | "scheduledTime"
  | "isDraft"
  | "engagementDate"
> & {
  hourlySlotStartTime?: string;
  scheduledTimeHour?: string;
};

export enum EngagementStatus {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
}

export type Student = {
  name: string;
  a0Number: string;
  monthsInTraining: string;
  course: string;
  company: string;
  apprenticeType: string;
  nextBlockCourse: string;
  blockCourseStartDate: string;
};

export type TEngagementDetails = { student: Student } & TEngagement;

export type TLearner = {
  learnerId: number;
  learnerName: string;
  courseId?: number;
  courseName?: string;
  courseDescription?: string;
  learnerSisUserId: string;
  learnerStatus?: string;
};

export interface TEngagementDb {
  id: number;
  timestamp: string;
  teacher_id: number;
  student_id: number;
  course_id: number;
  engagement_type: string;
  engagement_date: string;
  engagement_method: string;
  location: string | null;
  site_safety_5x5: string | null;
  participants: string;
  projects_worked_on: string | null;
  current_work: string | null;
  agreed_milestone_movements: string | null;
  learning_environment: string | null;
  scope_of_work: string | null;
  evaluator_comments: string | null;
  learner_comments: string | null;
  previous_goals_progress: string | null;
  agreed_goals_for_next_period: string | null;
  annual_progress_review: string | null;
  annual_development_goals: string | null;
  annual_support_plan: string | null;
  check_in_type: string | null;
  check_in_progress: string | null;
  check_in_fees: string | null;
  check_in_learner_engagement: string | null;
  check_in_lln: string | null;
  check_in_learning_environment: string | null;
  check_in_update_agreed_goals: string | null;
  is_draft: boolean;
  student_name: string;
  course_name: string;
  version: number;
  is_annual_review: string | null;
  notes: string | null;
  milestones: string | null;
  milestone_statements: string | null;
  learner_engagement: string | null;
  language_literacy_numeracy: string | null;
  goals_comments: string | null;
  professional_development_goals: string | null;
  agreed_learning_support: string | null;
  learning_experience_feedback: string | null;
  fees: string | null;
  next_visit_date: string | null;
  course_code: string | null;
  latestFaceToFace: TEngagementDb;
  latestAnnualReview: TEngagementDb;
  latestGoals: TEngagementDb;
}

export interface OutCome {
  title: string;
  description: string;
  isGoalSet: boolean;
  pointsPossible: number;
  masteryPoints: number;
  isCapstone?: boolean;
  rating: OutComeRating[];
}

export interface OutComeRating {
  description: string;
  points: number;
}

export interface UnitStandard {
  name: string;
  number: number;
  outcomes: OutCome[];
}
export interface TMilestone {
  assignmentGroupName: string;
  unitStandards: UnitStandard[];
  percentageComplete: number;
}

export interface SkillLevel {
  id: string;
  points: number;
  description: string;
  long_description: string;
}

export interface Skill {
  id: string;
  criterion: string;
  points: number;
  longDescription?: string;
  skillLevels?: SkillLevel[];
  currentSkillLevel?: SkillLevel;
  goal?: boolean;
}

export interface Assignment {
  id: number;
  name: string;
  grade: number;
  weight: number;
  skills: Skill[];
}

export interface AssignmentGroup {
  assignmentGroupId: number;
  assignmentGroupName: string;
  grade: number;
  gradeWeighted: number;
  assignments: Assignment[];
}

export interface AssignmentGroups {
  [name: string]: AssignmentGroup;
}

export interface SkillChange {
  skillName: string;
  skillLevel?: string;
  goal?: boolean;
}
export interface MilestoneChange {
  assignmentGroupName?: string;
  assignmentGroupId?: string;
  assignmentName: string;
  assignmentId?: string;
  skills: SkillChange[];
  fields?: string[];
}

export interface LearnerCRMData {
  learnerSisUserId: string;
  StartDate?: string;
  Course?: string;
  Months: string;
  ApprenticeType: string;
  Company: string;
}

export interface ServiceWorkerRegistration {
  sync: {
    register(tag: string): Promise<void>;
  };
}

export interface GoalComment {
  assignmentId: string;
  assignmentName: string;
  skillNames?: string[];
  comments: string;
}

export interface GoalData {
  assignmentId: string;
  assignmentName: string;
  skillNames?: string[];
  comments: string;
}

export type MilestoneAssignment = {
  assignmentName: string;
  assignmentPercentageComplete: string;
} & { [key: string]: MilestoneSkill | string };

export type MilestoneAssignmentGroup = {
  assignmentGroupName: string;
  assignmentGroupPercentageComplete: number;
  changePercentageWeighted?: number;
  changePercentage?: number;
} & { [key: string]: MilestoneAssignment | string | number };

export type Milestones = {
  totalPercentageDiff: number;
  totalCompletionPercentage: number;
} & { [key: string]: MilestoneAssignmentGroup | number };

export interface MilestoneSkill {
  goal: boolean;
  skillName: string;
}
export interface TError {
  link: string;
  data: string;
  fieldId: string;
}

export interface TEmployer {
  name: string;
  email: string;
}

export const OLD_CARPENTRY_COURSES: number[] = [
  101, 264, 94, 187, 189, 91, 98, 92, 2379, 2377, 2385, 2383, 2382, 2381, 2380,
  2384,
];
